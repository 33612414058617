<template>
  <div>
    <el-row style="margin-bottom: 20px">
      <el-col :span="3" style="margin-right: 15px;">
        <el-select
          v-model="ruleForm.province_id"
          clearable
          placeholder="请选择巡察组"
        >
          <el-option
            v-for="item in provinceids"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            @click.native="goto_Roles(item.id)"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px;">
        <el-select
          v-model="ruleForm.patrol_company_id"
          clearable
          placeholder="请选择巡察组"
        >
          <el-option
            v-for="item in provinceid"
            :key="item.id"
            :label="item.company_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px;">
        <el-input
          v-model="ruleForm.tel"
          placeholder="请输入内容手机号"
        ></el-input>
      </el-col>
      <el-col :span="4" style="margin-right: 15px;">
        <el-input v-model="ruleForm.name" placeholder="请输入内容"></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="init()">查询</el-button>
        <el-button type="" @click="add_data(1)">新增巡查人员</el-button>
      </el-col>
    </el-row>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="编号"> </el-table-column>
      <el-table-column prop="patrol_company_name" label="单位名称">
      </el-table-column>
      <el-table-column prop="patrol_role_name" label="单位类型">
      </el-table-column>
      <el-table-column prop="duties" label="职务"> </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="tel" label="负责人电话"> </el-table-column>
      <!-- 	<el-table-column prop="is_admin" label="管理员">
			<template slot-scope="scope">
			<span v-if="scope.row.is_admin===0" >是</span>
			<span v-if="scope.row.is_admin===1" >否</span>
			</template>
			</el-table-column> -->
      <el-table-column prop="" label="编辑">
        <template slot-scope="scope">
          <el-button @click="add_data(2, scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="display: flex;justify-content: center;align-items: center;margin-top: 15px"
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="ruleForm.page"
        :page-sizes="[10, 20, 30]"
        :page-size="ruleForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="ruleForm.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title_name"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      conten
    >
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="单位类型">
          <el-select
            v-model="form.user_type"
            placeholder="请选择单位类型"
            @change="goto_Roles"
          >
            <el-option
              v-for="item in provinceids"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称">
          <el-select
            v-model="form.patrol_company_id"
            @change="goto_company"
            placeholder="请选择单位名称"
          >
            <el-option
              v-for="item in provinceid"
              :key="item.id"
              :label="item.company_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职务">
          <el-input v-model="form.duties" placeholder="请输入职务"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.tel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <!-- 		<el-form-item label="是否管理员">
			   <el-radio-group v-model="form.is_admin">
			    <el-radio :label="0">是</el-radio>
			    <el-radio :label="1">否</el-radio>

			   </el-radio-group>
				</el-form-item> -->
        <el-form-item label="巡查人头像">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :http-request="uploadSectionFile2"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.avatar" :src="form.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="seaveCompanys()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AeraInfo from '@/assets/pca-code.json'
import { upload } from '@/utile/upload'
import {
  getRoles,
  provinceCityArea,
  get_patrols,
  post_patrols,
  put_patrols,
  get_PatrolCompany,
  get_InRecords
} from '@/api/manage'

export default {
  name: 'list',
  data () {
    return {
      options: AeraInfo,
      selectedOptions: [],
      provinceid: [],
      provinceids: [],
      ruleForm: {
        user_type: '',
        patrol_company_id: '',
        name: '',
        tel: '',
        page: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      form: {
        user_type: '',
        name: '',
        tel: '',
        patrol_company_id: '',
        avatar: '',
        is_admin: '',
        duties: ''
      },
      dialogVisible: false, //
      title_name: '',
      num_type: '',
      item_id: ''
    }
  },
  mounted () {
    this.init()
    this.Get_Roles()
  },
  methods: {
    init () {
      get_patrols(this.ruleForm).then(res => {
        this.tableData = res.data.data
        this.ruleForm.total = res.data.total
      })
    },
    //获取单位类型
    Get_Roles () {
      getRoles().then(res => {
        this.provinceids = res.data
      })
    },
    goto_Roles (value) {
      // this.form.patrol_company_id=""
      get_PatrolCompany({
        user_type: this.form.user_type
      }).then(res => {
        this.provinceid = res.data
        // for(let k in this.provinceid){
        // 	if(this.form.patrol_company_id===this.provinceid[k].patrol_company_id){}
        // 	this.form.patrol_company_id=this.provinceid[k].patrol_company_id
        // }
      })
    },
    goto_company (value) {
      console.log(value)
    },
    add_data (num, item) {
      console.log(item)
      this.num_type = num
      this.dialogVisible = true
      if (num === 1) {
        this.title_name = '新增巡查人员'
        this.Close()
      } else {
        this.item_id = item.id
        this.title_name = '编辑巡查人员'
        // this.form = item
        this.form.user_type = item.user_type
        this.form.duties = item.duties
        this.form.name = item.name
        this.form.tel = item.tel
        this.form.is_admin = item.is_admin
        this.form.avatar = item.avatar
        this.form.patrol_company_id = item.patrol_company_id
        this.goto_Roles()
        console.log(this.provinceid)
        console.log(this.form.user_type)
      }
    },
    //新增巡查单位
    seaveCompanys () {
      if (this.num_type === 1) {
        let ref = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
        if (!this.form.user_type) {
          return this.$message.error('请选择单位类型')
        } else if (!this.form.patrol_company_id) {
          return this.$message.error('请选择单位名称')
        } else if (!this.form.duties) {
          return this.$message.error('请填写职务名称')
        } else if (!this.form.name) {
          return this.$message.error('请填写姓名')
        } else if (!this.form.tel) {
          return this.$message.error('请填写手机号码')
        } else if (!ref.test(this.form.tel)) {
          return this.$message.error('请输入正确的手机号码')
        } else if (!this.form.avatar) {
          return this.$message.error('请上传头像图片')
        }

        post_patrols(this.form).then(res => {
          if (res.code === 200) {
            this.dialogVisible = false
            this.$message.success(res.data)
            this.init()
          }
        })
      } else {
        put_patrols(this.form, this.item_id).then(res => {
          if (res.code === 200) {
            this.dialogVisible = false
            this.$message.success(res.data)
            this.init()
          }
        })
      }
    },
    handleClose () {
      this.dialogVisible = false
      if (this.num_type === 1) {
        this.Close()
      }
    },
    Close () {
      ;(this.form.user_type = ''),
        (this.form.patrol_company_id = ''),
        (this.form.duties = ''),
        (this.form.name = ''),
        (this.form.tel = ''),
        (this.form.is_admin = ''),
        (this.form.avatar = '')
    },
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的路径
    uploadSectionFile2 (param) {
      // this.form.avatar = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.form.avatar = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    //分页功能
    handleSizeChange (val) {
      this.ruleForm.page = 1
      this.ruleForm.limit = val
      this.init()
    },
    handleCurrentChange (val) {
      this.ruleForm.page = val
      this.init()
    }
  }
}
</script>

<style lang="less" scoped>
.el-icon-plus {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.avatar {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>
